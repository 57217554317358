/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
}
.login-page .main-box {
  background-size: 100%;
  height: 814px;
  position: absolute;
  width: 1086px;
}
.login-page .main-box .form-box {
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(39, 54, 78, 0.12) 1px 0px 6px 0px;
  border: 2px solid #ccc;
  min-height: 440px;
  left: 635px;
  position: absolute;
  top: 196px;
  width: 362px;
  padding: 40px 75px;
}
.login-page .main-box .form-box .logo {
  text-align: center;
  font-size: 26px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Myriad Pro, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.login-page .main-box .form-box .form-row {
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  height: 40px;
}
.login-page .main-box .form-box .form-row .form-control {
  width: 0;
  flex: 1;
}
.login-page .main-box .form-box .error-container {
  min-height: 40px;
}
.login-page .main-box .form-box .login-btn {
  margin-top: 40px;
}
